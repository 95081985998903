
<template>
  <div class="container">
    <div class="main">
      <div class="box">
        <div class="title">
          <img src="" alt class="logo" />
          <span class="title-hn">帮助/反馈</span>
        </div>
        <div id="content" class="content">
          <div v-for="(item,index) in info" :key="index">
            <div class="info_r info_default" v-if="item.type == 'leftinfo'">
              <!-- <span class="circle circle_r"></span> -->
              <img class="circle circle_r" src="@/assets/img/logo2x.png" alt="">
              <div class="con_r con_text">
                <div>{{item.content}}</div>
                <div v-for="(item2,index) in item.question" :key="index">
                  <div class="con_que" @click="clickRobot(item2.content,item2.id)">
                    <div class="czkj-question-msg">
                      {{item2.index}}
                      {{item2.content}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="time_r">{{item.time}}</div>
            </div>

            <div class="info_l" v-if="item.type == 'rightinfo'">
              <div class="con_r con_text" style="background:none;">
                <span class="con_l">{{item.content}}</span>
                <!-- <span class="circle circle_l"> -->
                <img class="circle circle_l" :src="headimgurl" alt="">
                <!-- <img src class="pic_l" /> -->
                </span>
              </div>
              <div class="time_l">{{item.time}}</div>
            </div>
          </div>
        </div>

        <div class="setproblem">
          <textarea placeholder="请输入您的问题..."
            style="height: 85px;width: 100%;resize:none;padding-right:150px;outline: none;border-color:#ccc;border-radius:5px;"
            id="text" v-model="customerText" @keyup.enter="sentMsg()"></textarea>
          <button @click="sentMsg()" class="setproblems">
            <span style="vertical-align: 4px;">发 送</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
   metaInfo(){
     return{
        title: '帮助反馈',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  name: "onlineCustomer",
  components: {},
  computed: {},
  data() {
    return {
      headimgurl: localStorage.getItem("headimgurl"),
      customerText: "",
      info: [
        {
          type: "leftinfo",
          time: this.getTodayTime(),
          name: "robot",
          content:
            "您好，欢迎使用鹰眼投诉功能，为保证账户安全，在会话过程中请勿透露您的账号、",
          question: [],
        },
      ],
      timer: null,
      robotQuestion: [
        { id: 1, content: "我该如何发起投诉？", index: 1 },
        { id: 2, content: "为什么我的投诉没有通过审核？", index: 2 },
        { id: 3, content: "厂商多久可以解决我的问题？", index: 3 },
        {
          id: 4,
          content: "缺少确凿的证据能否投诉成功？",
          index: 4,
        },
        { id: 5, content: "商家拒绝赔偿怎么办？", index: 5 },
      ],
      robotAnswer: [
        {
          id: 1,
          content: "进入首页,点击我要投诉即可进行投诉",
          index: 1,
        },
        { id: 2, content: "请您确认您的资料填写无误", index: 2 },
        { id: 3, content: "一般1-3个工作日即可", index: 3 },
        {
          id: 4,
          content: "您好，资料填写完整更容易投诉成功",
          index: 4,
        },
        { id: 5, content: "您好，您可以进行投诉", index: 5 },
      ],
    };
  },
  created() {
    this.showTimer();
  },
  watch: {},
  methods: {
    // 用户发送消息
    sentMsg() {
      clearTimeout(this.timer);
      this.showTimer();
      let text = this.customerText.trim();
      if (text != "") {
        var obj = {
          type: "rightinfo",
          time: this.getTodayTime(),
          content: text,
        };
        this.info.push(obj);
        this.appendRobotMsg(this.customerText);
        this.customerText = "";
        this.$nextTick(() => {
          var contentHeight = document.getElementById("content");
          contentHeight.scrollTop = contentHeight.scrollHeight;
        });
      }
    },
    // 机器人回答消息
    appendRobotMsg(text) {
      clearTimeout(this.timer);
      this.showTimer();
      text = text.trim();
      let answerText = "";
      let flag;
      for (let i = 0; i < this.robotAnswer.length; i++) {
        if (this.robotAnswer[i].content.indexOf(text) != -1) {
          flag = true;
          answerText = this.robotAnswer[i].content;
          break;
        }
      }
      if (flag) {
        let obj = {
          type: "leftinfo",
          time: this.getTodayTime(),
          name: "robot",
          content: answerText,
          question: [],
        };
        this.info.push(obj);
      } else {
        answerText = "您可能想问：";
        let obj = {
          type: "leftinfo",
          time: this.getTodayTime(),
          name: "robot",
          content: answerText,
          question: this.robotQuestion,
        };
        this.info.push(obj);
      }
      this.$nextTick(() => {
        var contentHeight = document.getElementById("content");
        contentHeight.scrollTop = contentHeight.scrollHeight;
      });
    },
    sentMsgById(val, id) {
      clearTimeout(this.timer);
      this.showTimer();

      let robotById = this.robotAnswer.filter((item) => {
        return item.id == id;
      });
      let obj_l = {
        type: "leftinfo",
        time: this.getTodayTime(),
        name: "robot",
        content: robotById[0].content,
        question: [],
      };
      let obj_r = {
        type: "rightinfo",
        time: this.getTodayTime(),
        name: "robot",
        content: val,
        question: [],
      };
      this.info.push(obj_r);
      this.info.push(obj_l);
      this.$nextTick(() => {
        var contentHeight = document.getElementById("content");
        contentHeight.scrollTop = contentHeight.scrollHeight;
      });
    },
    // 点击机器人的单个问题
    clickRobot(val, id) {
      this.sentMsgById(val, id);
    },
    // 结束语
    endMsg() {
      let happyEnding = {
        type: "leftinfo",
        time: this.getTodayTime(),
        content: "通话结束",
        question: [],
      };
      this.info.push(happyEnding);
      this.$nextTick(() => {
        var contentHeight = document.getElementById("content");
        contentHeight.scrollTop = contentHeight.scrollHeight;
      });
    },
    showTimer() {
      this.timer = setTimeout(this.endMsg, 1000 * 60);
    },
    getTodayTime() {
      // 获取当前时间
      var day = new Date();
      let seconds = day.getSeconds();
      if (seconds < 10) {
        seconds = "0" + seconds;
      } else {
        seconds = seconds;
      }
      let minutes = day.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      } else {
        minutes = minutes;
      }
      let time =
        day.getFullYear() +
        "-" +
        (day.getMonth() + 1) +
        "-" +
        day.getDate() +
        " " +
        day.getHours() +
        ":" +
        minutes +
        ":" +
        seconds;
      return time;
    },
  },
  mounted() {},
  props: {},
  destroyed() {},
};
</script>
<style scoped>
.container {
  width: 960px;
  margin-left: 20px;
}
.main {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(149, 179, 212, 1) 0%,
    rgba(74, 131, 194, 1) 100%
  );
  overflow: hidden;
}
.title-hn {
  font-size: 20px;
  color: #ffffff;
}
.box {
  width: 100%;
  height: 650px;
  background-color: #fff;
  position: relative;
  padding: 1.25rem;
}
#content {
  height: 340px;
  overflow-y: scroll;
  font-size: 14px;
  width: 100%;
  margin-top: 70px;
}
.circle {
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #eff1f3;
}
.con_text {
  color: #333;
  margin-bottom: 5px;
}
.con_que {
  color: #1c88ff;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}
.info_r {
  position: relative;
}
.circle_r {
  position: absolute;
  left: 0%;
}
.pic_r {
  width: 17px;
  height: 17px;
  margin: 8px;
}
.con_r {
  display: inline-block;
  width: 55%;
  min-height: 55px;
background: #F9F9F9;
  border-radius: 6px;
  padding: 10px;
  margin-left: 40px;
}
.time_r {
  margin-left: 45px;
  color: #999999;
  font-size: 12px;
}
.info_l {
  text-align: right;
  color: #ffffff;
  color: #3163c5;
  margin-top: 10px;
  margin-right: 40px;
}
.pic_l {
  width: 13px;
  height: 17px;
  margin: 8px 10px;
}
.time_l {
  margin-right: 45px;
  color: #999999;
  font-size: 12px;
  margin-top: 5px;
}
.con_l {
  display: inline-block;
  width: 220px;
  min-height: 51px;
background: #F9F9F9;
  border-radius: 6px;
  padding: 10px;
  text-align: left;
  margin-right: 10px;
  word-wrap: break-word;
}
#question {
  cursor: pointer;
}
.setproblem {
  width: 96%;
  height: 68px;
  background-color: #ffffff;
  position: relative;
  margin-top: 3.75rem;
}
.setproblem textarea {
  color: #999999;
  padding: 10px;
  box-sizing: border-box;
}
.setproblem button {
  width: 5.875rem;
  height: 2.5rem;
  line-height: 2.5rem;
  background: #3163c5;
  opacity: 1;
  border-radius: 40px;
  font-size: 16px;
  color: #ffffff;
  position: absolute;
  right: 2.5%;
  top: 30%;
  cursor: pointer;
  border: none;
}
.title {
  background: #1f72d9;
  position: absolute;
  top: 0;
  width: 960px;
  height: 72px;
  left: 0;
  line-height: 72px;
  padding-left: 20px;
}
.czkj-item-title {
  line-height: 25px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.czkj-item-question {
  cursor: pointer;
  display: block;
  padding: 8px;
  position: relative;
  border-bottom: 1px dashed #ccc;
  line-height: 20px;
  min-height: 20px;
  overflow: hidden;
}

.czkj-question-msg {
  float: left;
  font-size: 14px;
  color: #3163c5;
}
</style>